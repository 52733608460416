import type {
  AiBlock,
  AiBlockMetaData,
  AiBlockType,
  ConditionComparator,
  DynamicOutputKey,
  Flow,
  FlowEdge,
  FlowNode,
  FlowPathCondition,
  FlowPathConditionGroup,
  FlowVersion,
  NodeInput,
  Prisma,
  PrismaClient,
} from '@prisma/client';
import { DefaultArgs } from '@prisma/client/runtime/library';
import type { Edge, Node, XYPosition } from 'reactflow';

import type { DYNAMIC_EDITOR_TAG_EXTENSION_NAME } from '@/components/dynamic-input/constants';
import type {
  AppSettingsComponents,
  IntegrationsProps,
} from '@/components/flow-canvas/components/side-menu/side-menu.types';
import type {
  CustomApp,
  CustomIntegrationProvider,
  IntegrationAppAuthentications,
  IntegrationAuthApp,
  NangoIntegrationProvider,
} from '@/levity/integrations/types';

import { AiBlockSettingsComponents } from '../../components/flow-canvas/components/side-menu/ai-block-menu/ai-block-menu.types';
import { ConectionConfigParamsType } from '../integrations/registry/constants';
import { OperationInput } from './operation-center.types';
import { getWorkflowsExecutions } from './temporal';

export type TransactionalClient = Omit<
  PrismaClient<Prisma.PrismaClientOptions, never, DefaultArgs>,
  '$transaction' | '$connect' | '$disconnect' | '$on' | '$use' | '$extends'
>;

export { type Flow, type FlowEdge, type FlowNode, FlowNodeType, type FlowVersion } from '@prisma/client';
export { FlowExecutionStatus, NodeExecutionStatus } from '@prisma/client';

export interface AppTrigger {
  id: string;
  description: string;
}

export interface AppTarget {
  id: string;
  description: string;
  compatibleWith: string[];
}

export interface AppMenuProps {
  integrations: IntegrationsProps;
  additionalSettingsComponents?: AppSettingsComponents;
}

export interface ChoseAiBlockProps {
  onCreateAiBlock: (aiBlockType: AiBlockType) => Promise<AiBlockWithMetadata>;
}

export interface AiBlockSettingsProps {
  aiBlock: AiBlockWithMetadata;
  additionalSettingsComponents: AiBlockSettingsComponents;
}

export type AiBlockMenuProps = Omit<AiBlockSettingsProps, 'aiBlock'> & {
  aiBlock?: AiBlockWithMetadata | undefined;
} & ChoseAiBlockProps;

export interface TestAiBlockProps {
  aiBlock: AiBlockWithMetadata | undefined;
  initialContext?: string;
  initialTestInput?: string;
}

export interface ChooseAppProps {
  integrations: IntegrationsProps;
}

export interface AppSettingsProps {
  integrations: IntegrationsProps;
  additionalSettingsComponents?: AppSettingsComponents;
}

export enum AppSource {
  ZENDESK = 'Zendesk',
  FRONT = 'Front',
  AIRTABLE = 'Airtable',
  GMAIL = 'Gmail',
  GSHEETS = 'Google Sheets',
  OUTLOOK = 'Outlook',
  OUTLOOK_V2 = 'OutlookV2',
  HUBSPOT = 'Hubspot',
  AI_BLOCK = 'AI Block',
  NETWORK = 'Network',
  YOUTUBE = 'YouTube',
  SLACK = 'Slack',
  FRESHDESK = 'Freshdesk',
  FRESHDESK_ENPAL = 'FreshserviceEnpal',
  WEBHOOK = 'Webhook',
  CUSTOM_CODE = 'Custom Code',
  DEVELOPMENT = 'Development',
  GW = 'Gebrüder Weiss',
}

/**
 * Represents an operation that can be performed.
 */
export interface SanitizedOperation extends OperationDefinition {
  provider: string;
  type: 'trigger' | 'target' | 'both';
  expectedParams: string[];
  return?: string[];
  outputKeys: OperationInput[];
}

export interface Operation extends SanitizedOperation {
  // eslint-disable-next-line @typescript-eslint/ban-types
  func: Function;
}

export interface FullApp {
  id: IntegrationAuthApp | CustomApp;
  title: string;
  icon: string;
  source: AppSource;
  provider: NangoIntegrationProvider | CustomIntegrationProvider;
  triggers: Operation[]; // filtered by type = 'trigger' or 'both'
  targets: Operation[]; // filtered by type = 'target' or 'both'
  connectionName:
    | ((authToken: string, connectionConfig?: ConectionConfigParamsType) => Promise<string | undefined>)
    | (() => string);
}
export interface App {
  id: string;
  title: string;
  icon: string;
  source: AppSource;
  provider: NangoIntegrationProvider | CustomIntegrationProvider;
  triggers: SanitizedOperation[]; // filtered by type = 'trigger' or 'both'
  targets: SanitizedOperation[]; // filtered by type = 'target' or 'both'
}

export enum CopyTagTypes {
  PREVIOUS_OPERATION_ACTION = 'PREVIOUS_OPERATION_ACTION',
  CURRENT_NODE_INPUTS = 'CURRENT_NODE_INPUTS',
}
export interface CustomTag {
  icon?: string;
  value?: string;
  type: CopyTagTypes;
}
export interface OperationCopies {
  operationContext: {
    title: string;
    tooltips?: {
      description: string;
    };
  };
  interactiveParameters?: {
    label: string;
    title: string;
    tooltips?: {
      description: string;
    };
  };
  actionDefinition: {
    customTags?: CustomTag[];
    elementTags: string[];
    action: string;
  };
}
export interface OperationDefinition {
  id: string;
  name: string;
  description: string;
  icon?: string;
  copies?: OperationCopies;
  hasDynamicInputs: boolean;
  requiresAuth: boolean;
  // This optional flag is used fot the cases where operations do not rely on the default dynamic input, but relies only on the custom settings.
  hideDefaultDynamicInput?: boolean;
  skipWorkflowPublishing?: boolean;
}

export interface OperationsDefinition {
  [key: string]: OperationDefinition;
}

export type ExtendedFlowNode = Omit<FlowNode, 'workspaceId' | 'versionId'> & {
  operationDefinition?: OperationDefinition | null;
};

export type FlowElement = Node | Edge;

export enum AppNodeType {
  TRIGGER = 'trigger',
  TARGET = 'target',
  BOTH = 'both',
}

export interface AppNodeData {
  integration: IntegrationAuthApp;
  type: AppNodeType;
  operationId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface PathNodeData {
  integration: IntegrationAuthApp;
  type: AppNodeType;
  operationId: string;
}

export interface PathNodeCondition {
  id: string;
  source: JSX.Element | null;
  comparator: ConditionComparator;
  comparisonValue: JSX.Element | null;
}

export enum AiBlockNodeConnectionType {
  LINEAR = 'linear',
  BRANCH = 'branch',
}

export interface AiBlockNodeData {
  aiBlockId: string;
  connectionType?: AiBlockNodeConnectionType;
}

export type NodeData = AppNodeData | AiBlockNodeData;

export interface AiBlockWithMetadata extends AiBlock {
  metadata: AiBlockMetaData[];
}

export interface NodeSettings {
  position?: XYPosition;
  tidy?: boolean;
}

export interface FlowWithNodesAndEdges extends Flow {
  nodes: FlowNode[];
  edges: FlowEdge[];
}

export enum EdgeType {
  DEFAULT = 'default',
}

export enum MenuState {
  IDLE = 'IDLE',
  APP = 'APP',
  AI_BLOCK = 'AI_BLOCK',
  PATH = 'PATH',
  WAIT = 'WAIT',
}

export enum IdleMenuState {
  SELECT_NODE = 'SELECT_NODE',
}

export enum AppNodeMenuState {
  CHOOSE_APP = 'CHOOSE_APP',
  CHOOSE_ACTION = 'CHOOSE_ACTION',
  SETTINGS = 'SETTINGS',
}

export enum AiBlockNodeMenuState {
  CHOOSE_AI_BLOCK = 'CHOOSE_AI_BLOCK',
  EDIT_AI_BLOCK = 'EDIT_AI_BLOCK',
  TEST_AI_BLOCK = 'TEST_AI_BLOCK',
}

export enum PathNodeMenuState {
  EDIT_PATH = 'EDIT_PATH',
}

export enum AttachmentProviders {
  OUTLOOK = 'Outlook',
  GMAIL = 'Gmail',
}

export enum WaitNodeMenuState {
  EDIT_WAIT = 'EDIT_WAIT',
}

export interface MenuStateAppNode {
  type: typeof MenuState.APP;
  state: AppNodeMenuState;
  data?: AppNodeData;
}

export interface MenuStateAiBlockNode {
  type: typeof MenuState.AI_BLOCK;
  state: AiBlockNodeMenuState;
  data?: AiBlockNodeData;
}

export interface MenuStatePathNode {
  type: typeof MenuState.PATH;
  state: PathNodeMenuState;
}

export interface MenuStateWaitNode {
  type: typeof MenuState.WAIT;
  state: WaitNodeMenuState;
}

export interface MenuStateIdle {
  type: typeof MenuState.IDLE;
  state: IdleMenuState;
}

export interface NewNodeSource {
  nodeId: string;
  handleId: string;
}

export type AppNode = Node<AppNodeData>;
export type AiBlockNode = Node<AiBlockNodeData>;
export type PathNode = Node;

export type NewNodeData = Partial<AppNodeData | AiBlockNodeData>;

export type NodeMenuState =
  | MenuStateIdle
  | MenuStateAppNode
  | MenuStateAiBlockNode
  | MenuStatePathNode
  | MenuStateWaitNode;

export interface FlowCanvasState {
  aiBlocks: AiBlockWithMetadata[];
  selectedNodeId?: string;
  newNodeData?: NewNodeData;
  newNodeSource?: NewNodeSource;
  menuState: NodeMenuState;
  isSideMenuOpen: boolean;
  availableIntegrations: Record<string, App>;
  availableOutputKeys: Record<string, Record<string, OperationInput>>;
  errors: ValidationErrors;
  editingNodeId?: string;
  draftName?: string;
  draftIcon?: string;
}

export interface NodesChangeset {
  nodes: { add?: Node[]; remove?: Node[]; update?: Node[] };
  edges: { add?: Edge[]; remove?: Edge[] };
  conditions?: {
    add?: FlowPathCondition[];
    remove?: string[];
    update?: FlowPathUpdatePayload[];
  };
}

export type FlowNodeWithoutConnection = Omit<FlowNode, 'connectionId'>;

export interface FlowNodesChangeset {
  nodes: {
    add?: FlowNodeWithoutConnection[];
    remove?: FlowNodeWithoutConnection[];
    update?: FlowNodeWithoutConnection[];
  };
  edges: { add?: FlowEdge[]; remove?: FlowEdge[] };
  conditions?: {
    add?: FlowPathCondition[];
    remove?: string[];
    update?: FlowPathUpdatePayload[];
  };
}

export interface FlowCanvasFunctions {
  updateNodesAndEdges: (nodes: Node[], edges: Edge[]) => void;
}

export type NodeExecutionResult =
  | (NodeResult & {
      pathResults?: Promise<NodeExecutionResult[]>[];
      skipChildren: boolean;
    })
  | {
      pathResults?: Promise<NodeExecutionResult[]>[];
      skipChildren: boolean;
    };

export type FlowTemplateNode = Pick<FlowNode, 'id' | 'type' | 'metadata'> & {
  defaultDynamicInputContent?: DynamicInputContentType[];
  connectedNodeInputId?: string;
  dynamicOutputKeys?: Pick<DynamicOutputKey, 'code' | 'default' | 'name' | 'provider' | 'rawName'>[];
  conditionGroups?: Array<
    Pick<FlowPathConditionGroup, 'logic'> & {
      conditions: Array<Omit<FlowPathCondition, 'id' | 'conditionGroupId' | 'createdAt' | 'updatedAt'>>;
    }
  >;
} & { icon: string };

export interface FlowTemplate {
  name: string;
  description: string;
  comingSoon?: boolean;
  integrations: IntegrationAuthApp[];
  title: string;
  aiBlocks: Array<
    Pick<AiBlock, 'id' | 'name' | 'emoji' | 'type'> & {
      metadata?: Pick<AiBlockMetaData, 'id' | 'type' | 'labelSource' | 'value' | 'key'>[];
    }
  >;
  nodes: FlowTemplateNode[];
  edges: Pick<FlowEdge, 'sourceHandle' | 'targetHandle' | 'sourceId' | 'targetId'>[];
}

export interface ValidationErrors {
  [nodeId: string]: string[];
}

export type NodesConnections = Record<string, IntegrationAppAuthentications | null>;

export interface DeleteConnectionCopy {
  title: string;
  description: string;
}
export interface Copy {
  activate: {
    disabled: string;
  };
  create: {
    error: string;
  };
  update: {
    error: string;
  };
  delete: {
    error: string;
    confirm: DeleteConnectionCopy;
  };
  publish: {
    start: string;
    error: string;
    success: string;
  };
  test: {
    start: string;
    error: string;
    success: string;
  };
  node: {
    linkConnection: {
      error: string;
    };
    updateMetadata: {
      error: string;
    };
  };
  connections: {
    delete: {
      confirm: (usageCount: number) => DeleteConnectionCopy;
      start: string;
      success: string;
      error: string;
    };
  };
  templates: {
    clone: {
      loading: string;
      error: string;
    };
  };

  validation: {
    nodes: {
      app: { auth: { error: string } };
      aiBlock: {
        minCategories: { error: string };
        dynamicInput: { error: string };
      };
    };
  };
  sideMenu: {
    aiBlocks: {
      dynamicInput: {
        label: string;
        tooltip: {
          description: string;
        };
      };
    };
  };
}

export interface TraversableNode {
  operation: string;
  nodeId: string;
  provider: string;
  outputKeys?: OperationInput[];
  children?: TraversableNode[];
}

export interface DynamicTagParams {
  prevNodeId: string;
  operation: string;
  key: string;
  image: { alt: string; src: string };
  isDefault: boolean;
}

export interface DefaultDynamicTagFlowTemplateParams {
  operation: string;
  key: string;
  image: { alt: string; src: string };
}

export type DynamicInputContentType =
  | { type: DynamicTagType.PARAGRAPH }
  | { type: DynamicTagType.TEXT; text: string }
  | {
      type: typeof DYNAMIC_EDITOR_TAG_EXTENSION_NAME;
      attrs: {
        dynamicTag: DefaultDynamicTagFlowTemplateParams;
      };
    };

export interface CreateDynamicInputContentParams {
  prevNodeId: string;
  content: DynamicInputContentType[];
}

export interface FlowVersionWithNodesAndEdges extends FlowVersion {
  nodes: FlowNode[];
  edges: FlowEdge[];
}

export interface FlowWithVersions extends Flow {
  versions: FlowVersionWithNodesAndEdges[];
}

// #region Dynamic Input content
interface ImageAttributes {
  alt: string;
  src: string;
}

interface DynamicTagAttributes {
  id: string;
  name: string;
  image: ImageAttributes;
  default: boolean;
}

export enum DynamicTagType {
  DYNAMIC_EDITOR_TAG = 'dynamic-editor-tag',
  PARAGRAPH = 'paragraph',
  TEXT = 'text',
  DOC = 'doc',
}

interface DynamicEditorTagType {
  type: DynamicTagType.DYNAMIC_EDITOR_TAG;
  attrs: {
    dynamicTag: DynamicTagAttributes;
  };
}

interface TextContentType {
  text: string;
  type: DynamicTagType.TEXT;
}

type ParagraphContent = TextContentType | DynamicEditorTagType;

interface ParagraphBlockType {
  type: DynamicTagType.PARAGRAPH;
  content: ParagraphContent[];
}

export interface DynamicInputRawValue {
  type: DynamicTagType.DOC;
  content: Array<ParagraphBlockType | DynamicEditorTagType>;
}

export interface FlowUINodeInput {
  operation?: string;
  nodeId?: string;
  key?: string;
  iconSrc?: string;
  value?: string;
  name?: string;
}
// #endregion Dynamic Input content

interface NodeInputReference {
  id: string;
  referenceKey: string;
  referenceKeyType: string;
  referenceCategory: string;
  nodeInputId: string;
  createdAt: Date;
}

export interface ReferencedNodeInput extends NodeInput {
  references?: NodeInputReference | null;
}

export type FlowTreeNode<T> = T & { children: FlowTreeNode<T>[] };
export interface NodeInputValue {
  reference?: NodeInputReference;
  value: string;
  nodeInputId?: string;
}

export interface FlowAttachmentMetadata {
  authToken: string;
  emailId: string;
  provider: string;
}

export type Provider = 'Outlook' | 'Gmail';

export interface BaseAttachment {
  provider: Provider;
  name: string; // Standardized field for attachment name
  contentType: string;
  size: number;
  contentBytes?: Buffer | string; //For Outlook attachments
  content?: string; // For Gmail attachments
}

export interface OutlookAttachment extends BaseAttachment {
  provider: 'Outlook';
  id?: string;
  isInline?: boolean;
  // Other Outlook-specific fields can be added here
}

export interface GmailAttachment extends BaseAttachment {
  provider: 'Gmail';
  type?: 'attachment';
  partId?: string;
  release?: null | string;
  contentDisposition?: string;
  contentId?: string;
  cid?: string;
  related?: boolean;
  headers?: Map<string, unknown>;
  checksum?: string;
  // Other Gmail-specific fields can be added here
}

export type FlowAttachment = OutlookAttachment | GmailAttachment;
export interface AttachmentSubtype {
  content?: string;
  contentBytes?: string;
  contentType: string;
}

export type NodeInputValues = [string, NodeInputValue[], FlowAttachmentMetadata?];

export type FlowPathUpdatePayload = Partial<Omit<FlowPathCondition, 'createdAt' | 'updatedAt'>> & {
  id: string;
};

export interface Connection {
  id: string;
  integrationAuthApp: IntegrationAuthApp;
  name: string;
  workspaceId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface NodeResult {
  nodeId: string;
  operation: string;
  result: {
    status: string;
    data: [string, Record<string, unknown>][];
    errorCode?: string;
    message?: string;
  };
  outputKeys: OperationInput[];
  provider: string;
}

export interface WorkflowsExecutionsResponse {
  nextPageToken: string | null;
  executions: Awaited<ReturnType<typeof getWorkflowsExecutions>>['executions'];
}
export type WorkflowExecutionInfo = WorkflowsExecutionsResponse['executions'][number];
